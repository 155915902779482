<template>
  <div>
    
    <Card class="py-3">
      <b-row>
        <b-col>
          <label>Canal</label>
          <b-form-select
            :options="selectOptionsChannels"
            v-model="selected.channel_id"
          ></b-form-select>
        </b-col>

        <template>
          <b-col>
            <label>Ativo</label>
            <b-form-select
              :options="[
                { text: 'Selecione', value: '', disabled: true },
                { text: 'Sim', value: '1' },
                { text: 'Não', value: '0' },
              ]"
              v-model="selected.active"
            >
            </b-form-select>
          </b-col>
          <b-col>
            <label>Aplicação</label>
            <b-form-select
              :options="selectOptionsUnityApplications"
              v-model="selected.application_id"
            ></b-form-select>
          </b-col>

          <b-col>
            <label>Tipo de Notificação</label>
            <b-form-select
              :options="selectOptionsNotificationTypes"
              v-model="selected.notification_type_id"
            ></b-form-select>
          </b-col>
        </template>
      </b-row>
      <hr />
      <div class="d-flex justify-content-end">
        <b-button @click="get_models">Salvar</b-button>
      </div>
    </Card>
    <Card :title="'Carregar arquivo txt ou html'" class="mt-3 pb-3"
      ><VueFileAgent class="mt-3" :multiple="false" v-model="uploadedFiles"
    /></Card>
    <Card :title="'Carregar texto html'" class="mt-3 pb-3">
      <b-textarea class="mt-3" rows="20" v-model="html" />
      <template #card-footer>
        <div class="d-flex justify-content-end mt-3">
          <b-button @click="$refs.previewHtml.open()">Visualizar</b-button>
        </div>
      </template>
    </Card>
    <SweetModal ref="previewHtml" width="80%">
      <iframe
        :srcdoc="html"
        frameborder="0"
        style="width: 100%; height: 75vh"
      ></iframe>
    </SweetModal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      html: "",
      uploadedFiles: [],
      selected: {
        channel_id: "",
        application_id: "",
        notification_type_id: "",
        profile_id: "",
        active: "",
        legacy: "",
      },
    };
  },
  watch: {
    uploadedFiles: {
      handler(file) {
        const reader = new FileReader();
        reader.onload = (file) => {
          const base64 = file.target.result.split(",");
          this.html = decodeURIComponent(escape(window.atob(base64[1])));
          this.$refs.previewHtml.open();
        };
        reader.readAsDataURL(file[0].file);
      },
    },
  },
  computed: {
    state() {
      return this.$store.state.admin;
    },
    applicationsById() {
      return _.keyBy(this.state.applications, "id");
    },
    selectOptionsUnityApplications() {
      const firstOption = { text: "Selecione", value: "", disabled: true };
      const normalized = _.map(this.state.unityApplications, (item) => {
        return {
          text: this.applicationsById[item.application_id].name,
          value: item.application_id,
        };
      });
      return [firstOption, ...normalized];
    },
    selectOptionsNotificationTypes() {
      const firstOption = { text: "Selecione", value: "", disabled: true };
      const normalized = _.map(this.state.notificationTypes, (item) => {
        return { text: item.description, value: item.id };
      });
      return [firstOption, ...normalized];
    },
    selectOptionsChannels() {
      const firstOption = {
        text: "Selecione um canal",
        value: "",
        disabled: true,
      };
      const normalized = _.map(this.state.channels, (item) => {
        return {
          text: item.channel,
          value: item.id,
          disabled: item.active == "1" ? false : true,
        };
      });
      return [firstOption, ...normalized];
    },
    models() {
      return this.state.templatesList;
    },
  },
  methods: {
    async get_models() {
      this.$store.dispatch("modelsCustom/get_models", this.selected);
    },
    check() {
      const reader = new FileReader();
      reader.onload = (file) => {
        const base64 = file.target.result.split(",");
        this.html = window.atob(base64[1]);
        this.$refs.previewHtml.open();
      };
      reader.readAsDataURL(this.uploadedFiles[0].file);
    },
  },
  mounted() {
    this.$store.dispatch("admin/list_init");
  },
};
</script>

<style>
</style>